import React from "react";

function BadgesFilter({ index, title, bgColor, handleFilter }) {
  return (
    <div className="flex items-center gap-1">
      <input
        id={index}
        type="checkbox"
        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
        onChange={handleFilter}
      />
      <label
        style={{ background: bgColor, color: "white" }}
        className="font-bold text-xs me-2 px-2.5 py-0.5 rounded flex justify-center"
        htmlFor={index}
      >
        {title}
      </label>
    </div>
  );
}

export default BadgesFilter;
